import * as Immutable from "immutable";

import { Decoration } from "./decoration";
import { BomColumnSetting } from "../responses/bomColumnSetting";

export class BomDecoration extends Decoration {

  protected readonly COLUMN_SETTINGS = "columnSettings";
  protected readonly CHILD_CONFIGURATION_COLUMN_SETTINGS = "childConfigurationColumnSettings";
  protected readonly IS_ALLOW_OPERATION_SEQUENCE_EDIT = "isAllowOperationSequenceEdit";
  protected readonly ALLOW_MANUALLY_ADDED_ITEMS = "allowManuallyAddedItems";
  protected readonly SHOW_IN_CONFIGURATOR = "showInConfigurator";
  protected readonly SHOW_IN_SUMMARY_AS_CALCULATED = "showInSummaryAsCalculated";
  protected readonly SHOW_IN_SUMMARY_AS_STORED = "showInSummaryAsStored";
  protected readonly PAGE_SIZE = "pageSize";
  protected readonly HAS_FLOW_TRIGGER = "hasFlowTrigger";

  get columnSettings(): Immutable.List<BomColumnSetting> { return this.getInternalValue<Immutable.List<BomColumnSetting>>(this.COLUMN_SETTINGS); }
  setColumnSettings(columnSettings: Immutable.List<BomColumnSetting>): this { return this.setInternalValue(this.COLUMN_SETTINGS, columnSettings); }

  get childConfigurationColumnSettings(): Immutable.List<BomColumnSetting> { return this.getInternalValue<Immutable.List<BomColumnSetting>>(this.CHILD_CONFIGURATION_COLUMN_SETTINGS); }
  setChildConfigurationColumnSettings(childConfigurationColumnSettings: Immutable.List<BomColumnSetting>): this { return this.setInternalValue(this.CHILD_CONFIGURATION_COLUMN_SETTINGS, childConfigurationColumnSettings); }

  get isAllowOperationSequenceEdit(): boolean { return this.getInternalValue<boolean>(this.IS_ALLOW_OPERATION_SEQUENCE_EDIT); }
  setIsAllowOperationSequenceEdit(isAllowOperationSequenceEdit: boolean): this { return this.setInternalValue(this.IS_ALLOW_OPERATION_SEQUENCE_EDIT, isAllowOperationSequenceEdit); }

  get allowManuallyAddedItems(): boolean { return this.getInternalValue<boolean>(this.ALLOW_MANUALLY_ADDED_ITEMS); }
  setAllowManuallyAddedItems(allowManuallyAddedItems: boolean): this { return this.setInternalValue(this.ALLOW_MANUALLY_ADDED_ITEMS, allowManuallyAddedItems); }

  get showInConfigurator(): boolean { return this.getInternalValue<boolean>(this.SHOW_IN_CONFIGURATOR); }
  setShowInConfigurator(showInConfigurator: boolean): this { return this.setInternalValue(this.SHOW_IN_CONFIGURATOR, showInConfigurator); }

  get showInSummaryAsCalculated(): boolean { return this.getInternalValue<boolean>(this.SHOW_IN_SUMMARY_AS_CALCULATED); }
  setShowInSummaryAsCalculated(showInSummaryAsCalculated: boolean): this { return this.setInternalValue(this.SHOW_IN_SUMMARY_AS_CALCULATED, showInSummaryAsCalculated); }

  get showInSummaryAsStored(): boolean { return this.getInternalValue<boolean>(this.SHOW_IN_SUMMARY_AS_STORED); }
  setShowInSummaryAsStored(showInSummaryAsStored: boolean): this { return this.setInternalValue(this.SHOW_IN_SUMMARY_AS_STORED, showInSummaryAsStored); }

  get pageSize(): number { return this.getInternalValue<number>(this.PAGE_SIZE); }
  setPageSize(pageSize: number): this { return this.setInternalValue(this.PAGE_SIZE, pageSize); }

  get hasFlowTrigger(): boolean { return this.getInternalValue<boolean>(this.HAS_FLOW_TRIGGER); }
  setHasFlowTrigger(hasFlowTrigger: boolean): this { return this.setInternalValue(this.HAS_FLOW_TRIGGER, hasFlowTrigger); }

}