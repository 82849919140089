import { Inject, Component, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import * as Immutable from "immutable";

import { BomDetailMessage } from "../../../shared/models/responses/messages";
import { ConfInfo, ConfBomValue, BomColumnSetting, GlobalSettings, RequestViews, Grid, BomDecoration } from "../../../shared/models";
import { BomStore } from "../../decorations/bom/bomStore";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { ProductDataStore } from "../../../shared/providers/productData";
import { AbstractPopupComponent } from "../../../../shared/components/popup/abstractPopupComponent";
import { ConfPageSessionService, ConfiguratorStore, ConfiguratorUIStore, PopupIdentifiers } from "../../providers";
import { RouteNames } from "../../../shared/providers";
import { PageStore } from "../../../shared/providers/page/pageStore";
import { PopupService } from "../../../../shared/components";
import { ManagedSubscription } from "../../../../shared";
import { BomActionArgs } from "../../decorations/bom/bomActionArgs";
import { ImageSets } from "../../../../shared/utils";

@Component({
  selector: 'bom-composite-popup',
  templateUrl: './bomCompositePopupComponent.html'
})
export class BomCompositePopupComponent extends AbstractPopupComponent {

  public id: string = PopupIdentifiers.BomComposite;

  public imageSet: string;

  public width: string;
  public compositeTitle: string; // Shows configuration's title in 2nd level header's title.
  public mainTitle: string;
  public displayChildConfigurations: boolean = false;

  public columnSettings = Immutable.List<BomColumnSetting>();
  public childColumnSettings = Immutable.List<BomColumnSetting>();

  public bomMessages = Immutable.List<BomDetailMessage>();
  public currentBomMessage: BomDetailMessage;

  public isSummary = false;
  protected activeConfId: number;
  public bomDecoration: BomDecoration;

  public stored = false;

  /** Stores the parent-child-childchild... stack.
      The active conf report is on top with its parent directly beneath it.
      |------------|
      | childchild |
      |   child    |
      |   parent   |
      |------------|
  */
  public stack: Immutable.Stack<ConfInfo> = Immutable.Stack<ConfInfo>();

  public bomSubscription: ManagedSubscription;

  @Output()
  closedEvent = new EventEmitter();

  constructor(
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(BomStore) public bomStore: BomStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ProductDataStore) public productDataStore: ProductDataStore,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(PopupService) public popupService: PopupService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr, popupService);
  }

  ngOnInit() {
    this.width = this.uiSettings.configurator.decoration.bom.minDialogWidth;
    this.imageSet = this.getImageSet(ImageSets.ConfiguratorBomPopupHeader);

    super.ngOnInit();
  }

  public updateBreadcrumb(confId: number): void {
    if (!confId)
      return;

    let confInfo: ConfInfo = this.confStore.getConfInfo(confId, this.confPageSessionService.confSessionId);

    this.stack = this.stack.unshift(confInfo);
    this.updateBreadcrumb(confInfo.parentId);
  }

  public open(tag: BomActionArgs) {

    if (this.bomSubscription)
      this.bomSubscription.unsubscribe();

    this.bomSubscription = this.bomStore.onGetBomDetailGridData(this.confPageSessionService.confSessionId, {
      next: (bomMessages: Immutable.List<BomDetailMessage>) => {
        this.showMessages(bomMessages, this.activeConfId ?? this.confPageSessionService.activeConfigurationId);
      }
      , listenNewEventsOnly: true
    });

    this.stored = tag.stored;

    this.show(tag.configurationId, tag.decorationId, tag.bomMessages);
  }
  /** Shows the composite detail for the given confId and decorationId. */
  show(confId: number, decorationId: number): void;
  /** Shows the composite detail with the given messages for the given confId and decorationId. */
  show(confId: number, decorationId: number, bomMessages: Immutable.List<BomDetailMessage>): void;
  show(confId: number, decorationId: number, bomMessages?: Immutable.List<BomDetailMessage>): void {

    this.activeConfId = confId;
    this.bomDecoration = this.productDataStore.getEntity<BomDecoration>(decorationId);
    this.isSummary = this.pageStore.activeRouteName === RouteNames.Summary;

    // Show messages directly if we got some, otherwise make a request for them.
    if (bomMessages) {
      this.showMessages(bomMessages, confId);
    }
    else {

      let clientType = this.pageStore.getActiveClientType();              
      this.bomStore.dispatchGetBomDetailGridData(clientType, confId, this.confPageSessionService.confSessionId, decorationId, 0, null, this.stored);
    }

    this.showInternal();
  }

  showMessages(messages, confId) {
    // Setup the tables
    this.bomMessages = messages;
    this.stack = Immutable.Stack<ConfInfo>();
        
    this.mainTitle = this.bomDecoration.title;

    this.columnSettings = this.bomDecoration.columnSettings;
    this.childColumnSettings = this.bomDecoration.childConfigurationColumnSettings;

    this.update(confId);
  }

  update(confIdArg: number | string): void {

    this.activeConfId = typeof confIdArg === "string" ? parseInt(confIdArg) : confIdArg;

    let confInfo: ConfInfo = this.confStore.getConfInfo(this.activeConfId, this.confPageSessionService.confSessionId);
    
    let bomMessage = this.bomMessages.find(x => x.configurationId === this.activeConfId);
    if (!bomMessage) {

      let clientType = this.pageStore.getActiveClientType();
      if (clientType == RequestViews.Editor)
        this.bomStore.dispatchGetBomDetailGridData(clientType, this.activeConfId, this.confPageSessionService.confSessionId, this.bomDecoration.longId, 0, null, this.stored);

      return;
    }

    this.currentBomMessage = bomMessage;
    this.compositeTitle = confInfo.text;

    if (!this.currentBomMessage || this.currentBomMessage.childrenGridData == null)
      return;

    this.displayChildConfigurations = this.currentBomMessage.childrenGridData.rows.first() != null;

    // Make breadcrumb only If composite configurations exist
    let rootConfInfo: ConfInfo = this.confStore.getConfInfo(confInfo.rootId, this.confPageSessionService.confSessionId);
    if (rootConfInfo.children.size > 0) {
      this.stack = this.stack.clear();
      this.updateBreadcrumb(this.activeConfId);
    }

    this.cdr.markForCheck();
  }

  public get globalSettings(): GlobalSettings {
    return this.globalDataStore.getGlobalData().globalSettings;
  }

  public onPopupClosed() {
    super.onPopupClosed();

    this.closedEvent.emit();

    if (this.bomSubscription)
      this.bomSubscription.unsubscribe();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.bomSubscription)
      this.bomSubscription.unsubscribe();
  }
}