<action-box *ngIf="visible"
            class="detail-item {{extraStyles}}"
            iconLeft="bom"
            textLeft="{{mainTitle}}"            
            [iconRight]="!isInnerTitleVisible && hideRightIcon && 'viewdetails'"
            [iconRightImageSet]="!isInnerTitleVisible && hideRightIcon && 'primary'"
            (onRightIconClick)="onDecorationHeaderIconClicked()"
            [showHeader]="showHeader">
  <div *ngIf="contentVisible" >
    <ng-container *ngFor="let model of models; trackBy: trackModel">
      <div *ngIf="isInnerTitleVisible" class="row bom-decoration-header">
        <div class="col">{{model.decoration.title}}</div>
        <div *ngIf="!model.storedBomValue || !model.confBomValue" class="col-auto mx-4" role="button" (click)="onDetailsClick(model.decoration, !model.confBomValue)"><co-image [width]="'32'" [height]="'32'" key="viewdetails" imageSet="primary"></co-image></div>
      </div>
      <ng-container *ngIf="model.storedBomValue && model.confBomValue">
        <div class="px-3 py-1 mt-1 row">
          <div class="col bom-type">{{strings.Current}}</div>
          <div class="col-auto" role="button" (click)="onDetailsClick(model.decoration)"><co-image [width]="'32'" [height]="'32'" key="viewdetails" imageSet="primary"></co-image></div>
        </div>
      </ng-container>
      <bom-decoration-contents *ngIf="model.confBomValue"
                               [decoration]="model.decoration"
                               [confBomValue]="model.confBomValue"
                               [showActionControls]="false"></bom-decoration-contents>
      <ng-container *ngIf="model.storedBomValue && model.confBomValue">
        <div class="px-3 py-1 mt-1 row">
          <div class="col bom-type">{{strings.Stored}}</div>
          <div class="col-auto" role="button" (click)="onDetailsClick(model.decoration, true)"><co-image [width]="'32'" [height]="'32'" key="viewdetails" imageSet="primary"></co-image></div>
        </div>
      </ng-container>
      <bom-decoration-contents *ngIf="model.storedBomValue"
                               [decoration]="model.decoration"
                               [confBomValue]="model.storedBomValue"
                               [showActionControls]="false"
                               [stored]="true"></bom-decoration-contents>
    </ng-container>
  </div>

  <bom-composite-popup *ngIf="compositeVisible" (closedEvent)="compositeVisible=false"></bom-composite-popup>
  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>