import { Component, Input, Inject, EventEmitter, ElementRef, ViewChild, HostListener, Output, SimpleChanges } from "@angular/core";
import { BaseComponent } from "../..";
import { ConfGraphicsValue, GraphicsDecoration, ConfValue, LookupParam, ConfLookupValue, ParamValue, Tab, GraphicsDisplayStyle, ConfGraphicsRequest, RequestViews, EventType } from "../../models";
import { GlobalDataStore } from "../../providers/globalData";
import { ConfPageSessionService, ConfiguratorStore } from "../../../configurator/providers";
import { ProductDataStore } from "../../providers/productData";
import { GraphicsHelper } from "./graphicsHelper";
import { PanZoomConfig } from "./panZoomConfig";
import { EmitterService } from "../../../configurator/shared";
import { SvgActions } from "./svgActions";
import { VisualObjectHelper } from "../../../configurator/parameters/shared";
import { PageStore } from "../../providers/page";
import { RouteNames, ModelFactory } from "../../providers";
import { ConfDataController } from "../../providers/configurationData";
import { HttpService } from "../../../../shared/providers";

@Component({
  selector: 'graphics-renderer',
  templateUrl: './graphicsRendererComponent.html',
})
export class GraphicsRendererComponent extends BaseComponent {

  public svg: string = "";

  public containerStyle: CSSStyleDeclaration;
  public isSummary = false;

  protected previousWidth = window.innerWidth;
  protected previousScale = 1;
  protected firstCall = true;

  public config: PanZoomConfig;

  @Input()
  width: string;

  @Input()
  height: string;

  calcHeight: number;

  @Input()
  paddingLeft: number = 0;

  @Input()
  paddingTop: number = 0;

  @Input()
  decoration: GraphicsDecoration;

  @Input()
  public confId: number;

  @Input()
  public sessionId: number;

  @ViewChild('container')
  public container: ElementRef;

  // Events
  mouseDownMS: number;
  lastClickMS: number;
  initialMouseX: number;
  initialMouseY: number;

  lastTouchX: number = NaN;
  lastTouchY: number = NaN;

  selectedElement: any;
  offset: any;

  lastDx: number;
  lastDy: number;
  scale: number;
  panX: number;
  panY: number;

  // Line snapping
  lineReset = true;
  lineStartX2 = null;
  lineStartY2 = null;
  lineStartX1 = null;
  lineStartY1 = null;

  constructor(
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(GraphicsHelper) public graphicsHelper: GraphicsHelper,
    @Inject(PageStore) public pageStore: PageStore,
    @Inject(VisualObjectHelper) public visualObjectHelper: VisualObjectHelper,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(ConfDataController) public configuratorController: ConfDataController,
    @Inject(HttpService) public httpService: HttpService,
  ) {
    super();
  }

  ngOnInit(): void {

    this.isSummary = this.pageStore.activeRouteName === RouteNames.Summary;
    this.config =
      {
        identifier: this.decoration.longId,
        initialOrigoPosition: this.decoration.initialOrigoPosition,
        initialX: this.decoration.initialOffsetX + this.paddingLeft,
        initialY: this.decoration.initialOffsetY + this.paddingTop,
        initialZoomFactor: this.decoration.initialZoomFactor || 1,
        responsive: this.decoration.responsiveSize,
        realWidth: this.decoration.width,
        realHeight: this.decoration.height
      } as PanZoomConfig;

    this.updateStyles();
  }

  getURL(): string {

    let model: ConfGraphicsRequest = this.modelFactory.createAny(ConfGraphicsRequest.name) as any;
    model.client = this.pageStore.getActiveClientType();
    model.fileType = "svg";
    model.configurationId = this.confId;
    model.confSessionId = this.pageStore.activeRouteName == RouteNames.Start ? this.sessionId : this.confPageSessionService.confSessionId;
    model.graphicsDecorationId = this.decoration.visualObjectId;
    return this.configuratorController.getConfigurationGraphicUrl(model);
  }

  init(): void {

    // TODO: Need to improve this part, sessionId must be passed through arguments, this method won't work if it is called while viewing the graphics from start page.
    let value = this.confStore.getConfDataEntity<ConfGraphicsValue>(this.confId, this.confPageSessionService.confSessionId, this.decoration.visualObjectId);

    if (this.svg)
      this.UnBindSvgEvents();

    if (value)
      this.svg = value.svg;

    this.updateHtml();
    if (this.firstCall) {
      this.firstCall = false;
      setTimeout(() => { this.emitterService.send(SvgActions.Render, this.config.identifier); }, 0);
    }
    else
      this.emitterService.send(SvgActions.Render, this.config.identifier);

    setTimeout(() => {
      this.BindSvgEvents();
    }, 0);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateStyles();
  }

  protected updateStyles() {

    this.containerStyle = {} as CSSStyleDeclaration;

    if (this.decoration.responsiveSize) {

      if (this.decoration.height && this.visualObjectHelper.isVisualObjectInAccordion(this.decoration))
        this.containerStyle.height = `${this.decoration.height}px`;

      this.containerStyle.width = '100%';

    }
    else {

      this.containerStyle.maxWidth = this.isSummary || this.decoration.displayStyle === GraphicsDisplayStyle.Popup ? '100%' : this.visualObjectHelper.visualObjectWidth(this.decoration);
      this.containerStyle.width = '100%';

      if (this.decoration.height && this.decoration.displayStyle !== GraphicsDisplayStyle.Popup)
        this.containerStyle.height = `${this.decoration.height}px`;

    }

  }

  ngAfterViewInit() {
    if (this.decoration.displayStyle == GraphicsDisplayStyle.Render)
      this.confStore.onConfGraphicValueChange(this.confId, this.confPageSessionService.confSessionId, this.decoration.visualObjectId, (graphic => {
        this.init();
      })).unsubscribeOn(this.unsubscribeSubject)
    else if (this.decoration.displayStyle == GraphicsDisplayStyle.Popup) {
      // make call to server
      let url = this.getURL();

      this.httpService.readContents(url).then(data => {

        this.svg = data;
        this.updateHtml();
        this.emitterService.send(SvgActions.Render, this.config.identifier);
      });
    }
  }

  public updateHtml() {
    if (this.container) {

      if (this.svg) {

        // Append unique id to toplevel and composite graphic to make it function properly. (match IDs like "topLevel", "CompositeGraphic", "PG[0-9]")
        let uniquenessRegex = /(svg|use|symbol) ((href|id)=\"#?(topLevel|CompositeGraphic|PG\d[^\"]+))/g;
        let uniqueSvg = this.svg.replace(uniquenessRegex, "$&_" + this.decoration.longId /* Use longId to make sure its unique for clones */);
        this.container.nativeElement.innerHTML = uniqueSvg;

      }
      else {
        this.container.nativeElement.innerHTML = "";
      }

    }
  }

  svgControlIconsAction(event): void {
    this.emitterService.send(event, this.config.identifier);
  }

  // Svg events
  public UnBindSvgEvents() {
    if (this.confPageSessionService.activeRoute != RouteNames.Editor)
      return;

    // Reference to the base SVG element
    let baseSvgElement = this.container.nativeElement.firstElementChild;

    // Unbind custom events
    baseSvgElement.removeEventListener('custommousedown', this.startDrag);
    baseSvgElement.removeEventListener('customclick', this.attributeClicked);
    baseSvgElement.removeEventListener('custommove', this.drag, { passive: true } as AddEventListenerOptions);
    baseSvgElement.removeEventListener('custommoveend', this.endDrag, { passive: true } as AddEventListenerOptions);

    // Unbind standard mouse and touch events
    baseSvgElement.onmousedown = null;
    baseSvgElement.removeEventListener('ontouchstart', this.onMouseDown, { passive: true } as AddEventListenerOptions);
    baseSvgElement.ontouchend = null;
    baseSvgElement.onmouseup = null;
    baseSvgElement.onmousemove = null;
    baseSvgElement.removeEventListener('touchmove', this.drag, { passive: true } as AddEventListenerOptions);
  }

  public BindSvgEvents() {
    if (this.confPageSessionService.activeRoute != RouteNames.Editor)
      return;

    // Listen for custom svg events
    let baseSvgElement = this.container.nativeElement.firstElementChild;

    baseSvgElement.addEventListener('custommousedown', (event) => {
      this.startDrag(event);
    });

    baseSvgElement.addEventListener('customclick', (event) => {
      this.attributeClicked(event);
    });

    // Workaround for when touch move get cancelled when applying a transformation to the dragged element, also requires a touch move listener on the base svg.
    baseSvgElement.addEventListener('custommove', (event) => {
      if (event?.detail?.clientX) {
        this.drag(event);
      }
    }, { passive: true } as AddEventListenerOptions);

    baseSvgElement.addEventListener('custommoveend', (event) => {
      this.endDrag(event);
    }, { passive: true } as AddEventListenerOptions);

    baseSvgElement.onmousedown = (e) => this.onMouseDown(e);
    baseSvgElement.addEventListener('ontouchstart', (e) => this.onMouseDown(e), { passive: true } as AddEventListenerOptions);
    baseSvgElement.ontouchend = (e) => this.endDrag(e);
    baseSvgElement.onmouseup = (e) => this.endDrag(e);
    baseSvgElement.onmousemove = (e) => this.drag(e);
    baseSvgElement.addEventListener('touchmove', (e) => this.drag(e), { passive: true } as AddEventListenerOptions);
  }

  public onMouseDown($event): void {
    this.mouseDownMS = Date.now();
    this.initialMouseX = $event.clientX;
    this.initialMouseY = $event.clientY;
  }

  public attributeClicked($event): void {
    if (Date.now() - this.lastClickMS < 500)
      return;

    this.lastClickMS = Date.now();

    if (!this.isClickValid($event))
      return;

    if ($event.target) {
      let target = $event.target;

      let argument = target?.getAttribute("click-attribute");
      let symbolId = +target?.getAttribute("symbol-id");
      let graphicsConfId = +target?.getAttribute("conf-id");

      this.emitterService.send(SvgActions.SetZoom, this.config.identifier);

      if (argument) {
        let json = JSON.stringify({ argument: argument });

        this.confStore.javaScriptAction(graphicsConfId, this.confPageSessionService.confSessionId, EventType.Clicked, symbolId, json, RequestViews.Editor);
      }
    }
  }

  public startDrag($event) {
    if (this.selectedElement)
      return;

    this.onMouseDown($event);

    let target = $event.target;

    if (!target)
      return;

    this.selectedElement = target;
    this.offset = null;

    this.lineReset = true;

    this.emitterService.send(SvgActions.DisablePan, this.config.identifier);
  }

  public drag($event) {
    if (this.selectedElement) {
      if (!this.offset) {
        this.offset = this.getMousePosition($event);
        return;
      }

      var coord = this.getMousePosition($event);
      var dx = coord.x - this.offset.x;
      var dy = coord.y - this.offset.y;

      this.scale = this.container.nativeElement.firstElementChild.lastElementChild.transform.baseVal[0].matrix.a;
      this.panX = this.container.nativeElement.firstElementChild.lastElementChild.transform.baseVal[0].matrix.e;
      this.panY = this.container.nativeElement.firstElementChild.lastElementChild.transform.baseVal[0].matrix.f;

      this.lastDx = dx / this.scale;
      this.lastDy = dy / this.scale;

      if (this.selectedElement) {
        // Apply transformation to the last nested element
        let elems = this.container.nativeElement.querySelectorAll("#" + this.selectedElement.id);
        if (elems.length > 0) {
          let lastIndex = elems.length - 1;

          for (let i = 0; i < elems[lastIndex]?.viewportElement?.children.length; i++) {
            let elem = elems[lastIndex]?.viewportElement?.children[i];
            if (elem.getAttribute("click-attribute")?.indexOf('hide-on-drag') > -1)
              elem.setAttribute("hidden", "true");
          }

          if (this.selectedElement.getAttribute("click-attribute")?.indexOf('drag-line') < 0) {
            elems[elems.length - 1].setAttribute("transform", `translate(${this.lastDx}, ${this.lastDy})`);
            return;
          }
          
          if (elems[lastIndex]?.viewportElement?.firstElementChild?.nodeName == 'line') {
            let coordinateIndex = this.selectedElement.getAttribute("click-attribute")?.indexOf('left') ? 2 : 1;
            let otherCoordinateIndex = coordinateIndex > 1 ? 1 : 2;
            // FIRST TIME
            if (this.lineReset) {
              this.lineStartX2 = +elems[lastIndex].viewportElement.firstElementChild.getAttribute("x" + coordinateIndex);
              this.lineStartY2 = +elems[lastIndex].viewportElement.firstElementChild.getAttribute("y" + coordinateIndex);
              this.lineStartX1 = +elems[lastIndex].viewportElement.firstElementChild.getAttribute("x" + otherCoordinateIndex); // usually zero
              this.lineStartY1 = +elems[lastIndex].viewportElement.firstElementChild.getAttribute("y" + otherCoordinateIndex);
              this.lineReset = false;
            }

            // line coordinates
            let newx = this.lastDx + this.lineStartX2;
            let newy = this.lastDy + this.lineStartY2;

            let diffx = this.lastDx + this.lineStartX2 - this.lineStartX1;
            let diffy = this.lastDy + this.lineStartY2 - this.lineStartY1;

            // IF SNAP
            if (this.selectedElement.getAttribute("click-attribute")?.indexOf('snap') > -1) {
              let arr = [Math.abs(diffx), Math.abs(diffy)];

              if (this.selectedElement.getAttribute("click-attribute")?.indexOf('snap45') > -1) {
                let dNEGXY = Math.abs(diffx - diffy) / Math.sqrt(2);
                let dPOSXY = Math.abs(diffx + diffy) / Math.sqrt(2);
                arr = arr.concat([dPOSXY, dNEGXY]);
              }

              let ind = arr.indexOf(Math.min(...arr));
              let dist = 0;

              switch (ind) {
                case (0):
                  newx = this.lineStartX1;
                  break;
                case (1):
                  newy = this.lineStartY1;
                  break;
                case (2):
                  dist = (diffx - diffy) / 2
                  newx = this.lineStartX1 + dist;
                  newy = this.lineStartY1 - dist;
                  break;
                case (3):
                  dist = (diffx + diffy) / 2
                  newx = this.lineStartX1 + dist;
                  newy = this.lineStartY1 + dist;
                  break;
              }

              this.lastDx = newx - this.lineStartX2;
              this.lastDy = newy - this.lineStartY2;
            }

            elems[lastIndex].setAttribute("transform", `translate(${this.lastDx}, ${this.lastDy})`);

            elems[lastIndex].viewportElement.firstElementChild.setAttribute("x" + coordinateIndex, newx);
            elems[lastIndex].viewportElement.firstElementChild.setAttribute("y" + coordinateIndex, newy);
          }
        }
      }
    }
  }

  public endDrag($event) {
    if (!this.selectedElement)
      return;

    if (!this.isClickValid($event) && this.isDragValid()) {
      let argument = this.selectedElement.getAttribute("click-attribute");

      let symbolId = +this.selectedElement.getAttribute("symbol-id");

      let graphicsConfId = +this.selectedElement.getAttribute("conf-id");

      this.lastClickMS = Date.now();

      let getZeroIfNan = (x) => isNaN(x) ? 0 : x;     

      let json = JSON.stringify({ argument: argument, dx: getZeroIfNan(this.lastDx), dy: getZeroIfNan(this.lastDy) });

      this.confStore.javaScriptAction(graphicsConfId, this.confPageSessionService.confSessionId, EventType.Dropped, symbolId, json, RequestViews.Editor);
    }

    this.selectedElement = false;

    this.emitterService.send(SvgActions.EnablePan, this.config.identifier);

    this.emitterService.send(SvgActions.SetZoom, this.config.identifier);
  }

  // Svg events helper methods
  private isDragValid(): boolean {

    let clickTime = new Date().getTime() - this.mouseDownMS;

    if (clickTime > 300)
      return true;

    if (this.offset)
      this.restorePosition();
    return false;
  }

  private restorePosition() {
    this.drag({ clientX: this.initialMouseX, clientY: this.initialMouseY });
  }

  private isClickValid($event): boolean {

    let clickTime = new Date().getTime() - this.mouseDownMS;
    let xDelta = 0;
    let yDelta = 0;

    if ($event.clientX) {
      xDelta = Math.abs(this.initialMouseX - $event.clientX);
      yDelta = Math.abs(this.initialMouseY - $event.clientY);
    }
    else if (!isNaN(this.lastTouchX)) {
      xDelta = Math.abs(this.initialMouseX - this.lastTouchX);
      yDelta = Math.abs(this.initialMouseY - this.lastTouchY);

      this.lastTouchX = NaN;
      this.lastTouchY = NaN;
    }

    if (clickTime > 500 || xDelta > 2 || yDelta > 2)
      return false;
    else
      return true;
  }

  private getMousePosition($event) {
    let CTM = this.container.nativeElement.children[0].getScreenCTM();
    if ($event.touches) { $event = $event.touches[0]; }
    if ($event.detail) { $event = $event.detail; }
    return {
      x: ($event.clientX - CTM.e) / CTM.a,
      y: ($event.clientY - CTM.f) / CTM.d
    };
  }
}