import { Component, Inject, Input, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { BaseComponent } from "../../../../../core/pages/shared";
import { PopupService } from "../../../../shared/components";
import { DataRowView } from "../../../../shared/components/shared";
import { Delay, sleep, ImageSets } from "../../../../shared/utils";
import { PagerModel } from "../../../shared/components/pager/pagerModel";
import { BomDecoration, ConfBomValue } from "../../../shared/models";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { ProductDataStore } from "../../../shared/providers/productData";
import { ConfiguratorStore, ConfPageSessionService, PopupIdentifiers } from "../../providers";
import { BomActionArgs } from "./bomActionArgs";
import { BomActionCreator } from "./bomActionCreator";
import { BomController } from "./bomController";
import { BomStore } from "./bomStore";
import { PagerService } from "../../../shared/components/pager/pagerService";

@Component({
  selector: 'bom-decoration-contents',
  templateUrl: './bomDecorationContentsComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BomStore, BomActionCreator, BomController]
})
export class BomDecorationContentsComponent extends BaseComponent {

  public selectedRow: DataRowView;

  public pagerModel: PagerModel;

  public refreshPager: boolean = false;

  public displayChildConfigurations = false;

  public allowManuallyAddedItems: boolean = false;

  public imageSet: string;

  public addVisible: boolean = false;
  public editVisible: boolean = false;
  public deleteVisible: boolean = false;
  public compositeVisible: boolean = false;

  @Input()
  public confBomValue: ConfBomValue;

  @Input()
  public showActionControls: boolean = true;

  @Input()
  public decoration: BomDecoration;

  @Input()
  public stored: boolean = false;

  constructor(
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(BomStore) public bomStore: BomStore,
    @Inject(PopupService) public popupService: PopupService,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(PagerService) public pagerService: PagerService,
    @Inject(ChangeDetectorRef) public cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.allowManuallyAddedItems = this.decoration.allowManuallyAddedItems;

    this.imageSet = this.getImageSet(ImageSets.ConfiguratorBomActions);

    // the configuration might consist of an old pagination, so try to use it.
    this.pagerModel = this.pagerService.create(this.confBomValue.gridData.pagination.pageSize, this.confBomValue.gridData.pagination.pageIndex, this.confBomValue.gridData.pagination.noOfPages, this.confBomValue.gridData.pagination.resultCount, ["5", "10", "20", "50", "100", "200", this.strings.All]);

    this.setValue(null);
    super.ngOnInit();

    if (this.decoration && this.decoration.pageSize)
      this.pagerService.updatePageSizes(this.decoration.pageSize, this.pagerModel);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes["confBomValue"] && !changes["confBomValue"].firstChange) {

      // Set the decoration
      if (this.confBomValue) {
        this.decoration = this.productStore.getEntity<BomDecoration>(this.confBomValue.longId);
      }

      this.setValue(null);
    }

  }

  setValue(itemValues: Map<number, any>): void {

    this.displayChildConfigurations = this.confBomValue.childrenGridData != null && this.confBomValue.childrenGridData.rows.size > 0;

    if (this.confBomValue && this.confBomValue.gridData) {
      this.pagerService.update(this.confBomValue.gridData.pagination, this.pagerModel);
      this.pagerModel = { ...this.pagerModel };
      this.refreshPager = !this.refreshPager;
      this.cdr.markForCheck();
    }
  }

  public refreshGrid() {
    let clientRequestType = this.confPageSessionService.confUIStore.PageStore.getActiveClientType();
    this.bomStore.dispatchGetBomGridData(clientRequestType, this.confPageSessionService.activeConfigurationId, this.confPageSessionService.confSessionId, this.decoration.longId, this.pagerModel.displayCountView.displayAll ? 0 : this.pagerModel.displayCountView.count, this.pagerModel.currentPage, this.stored);
  }

  selectRow(row: DataRowView) {
    this.selectedRow = row;
  }

  public closedEvent($event) {
    this.addVisible = false;
    this.editVisible = false;
    this.deleteVisible = false;
    this.compositeVisible = false;
  }

  public addBom() {
    this.addVisible = true;
    sleep(Delay.MS_100).then(x => { this.popupService.open<BomActionArgs>(PopupIdentifiers.BomCreatePopup, <BomActionArgs>{ decorationId: this.decoration.longId }); });
  }

  public editBom() {
    if (this.selectedRow == null)
      return;

    this.editVisible = true;
    sleep(Delay.MS_100).then(x => { this.popupService.open<BomActionArgs>(PopupIdentifiers.BomEditPopup, <BomActionArgs>{ decorationId: this.decoration.longId, selectedRowId: parseInt(this.selectedRow.id) }); });
  }

  public removeBom() {
    if (this.selectedRow == null)
      return;

    this.deleteVisible = true;
    sleep(Delay.MS_100).then(x => { this.popupService.open<BomActionArgs>(PopupIdentifiers.BomDeletePopup, <BomActionArgs>{ decorationId: this.decoration.longId, selectedRowId: parseInt(this.selectedRow.id) }); });
  }

  public onBomCompositeClick(): void {
    this.compositeVisible = true;
    sleep(Delay.MS_100).then(x => { this.popupService.open<BomActionArgs>(PopupIdentifiers.BomComposite, <BomActionArgs>{ configurationId: this.confPageSessionService.activeConfigurationId, decorationId: this.decoration.longId }); });
  }
}