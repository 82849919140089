import { AbstractConfDataCommand } from "./abstractConfDataCommand";
import { GridArgument } from "../arguments/gridArgument";
import { BomItem } from "./bomItem";

export class BomCommand extends AbstractConfDataCommand {
  bomDecorationId?: number;
  search?: string;

  gridData?: GridArgument;
  detailGridData?: GridArgument;

  create?: BomItem;
  getItem?: BomItem;
  edit?: BomItem;
  remove?: BomItem;

  stored?: boolean;
}