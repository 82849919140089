import { Inject, Component, ViewChild, ChangeDetectorRef, Input } from "@angular/core";
import * as Immutable from "immutable";
import { ConfiguratorStore, ConfPageSessionService, ConfiguratorUIStore, PopupIdentifiers } from "../../providers";
import { BaseDetailItemComponent } from "../shared/baseDetailItemComponent";
import { BomStore, BomActionCreator, BomController } from "../../decorations/bom";
import { BomColumnSetting, Conf, RequestViews, Product, Tab, BomDecoration, Decoration, Grid, GlobalSettings, ConfBomValue, GroupConfUIItem, StoredBomValue } from "../../../shared/models";
import { BomCompositePopupComponent } from "../../popups";
import { PagerModel } from "../../../shared/components/pager/pagerModel";
import { ChangeDetectionStrategy } from "@angular/core";
import { PopupService } from "../../../../shared/components";
import { ProductDataStore } from "../../../../../core/pages/shared/providers/productData";
import { BomActionArgs } from "../../decorations/bom/bomActionArgs";
import { sleep, Delay } from "../../../../shared/utils";

export class UIBomModel {
  decoration: BomDecoration;
  confBomValue: ConfBomValue;
  storedBomValue: StoredBomValue;
}

@Component({
  selector: 'bom-detail',
  templateUrl: './bomDetailComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BomDetailComponent extends BaseDetailItemComponent {

  public displayChildConfigurations: boolean = false;

  public columnSettings = Immutable.List<BomColumnSetting>();
  public childColumnSettings = Immutable.List<BomColumnSetting>();

  public pagerModel: PagerModel;
  public compositeVisible: boolean = false;

  public mainTitle: string;
  public isInnerTitleVisible: boolean = false;
  public hideRightIcon: boolean = false;

  public models: Array<UIBomModel> = [];

  public get configuration(): Conf {
    return this.confStore.getConf(this.configurationId, this.confPageSessionService.confSessionId);
  }

  @ViewChild(BomCompositePopupComponent)
  bomCompositePopup: BomCompositePopupComponent;

  constructor(@Inject(ConfiguratorStore) public confStore: ConfiguratorStore,
    @Inject(ConfiguratorUIStore) public configuratorUIStore: ConfiguratorUIStore,
    @Inject(ConfPageSessionService) public confPageSessionService: ConfPageSessionService,
    @Inject(BomStore) public bomStore: BomStore,
    @Inject(ProductDataStore) public productStore: ProductDataStore,
    @Inject(ChangeDetectorRef) public cd: ChangeDetectorRef,
    @Inject(PopupService) public popupService: PopupService
  ) {
    super(confPageSessionService);
  }

  onDataReady(): void {

    this.initUIModels();

    this.visible = this.models.length > 0;

    if (!this.visible)
      return;

    this.mainTitle = this.strings.BillOfMaterials;
    this.isInnerTitleVisible = this.models.length > 1;
    this.hideRightIcon = this.models.length > 0 && !!this.models[0].confBomValue && !!this.models[0].storedBomValue;

    if (!this.isInnerTitleVisible) {
      let firstDecoration = this.models[0].decoration;
      if (firstDecoration.title && firstDecoration.title.trim().length > 0)
        this.mainTitle = firstDecoration.title;
      // For individually displayed decoration if there is no title then hide the whole header row
      else if (typeof this.confUIItem != typeof GroupConfUIItem)
        this.showHeader = false;
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this.listenChanges();
  }

  public listenChanges(): void {
    this.confStore.onConfigurationChange(this.configurationId, this.confPageSessionService.confSessionId, (configuration: Conf): void => {
      this.models.forEach(model => {
        model.confBomValue = configuration.bomValues.find((x) => x.longId == model.decoration.longId);
        model.storedBomValue = configuration.storedData.bomValues.find((x) => x.longId == model.decoration.longId);
      });

      this.cd.markForCheck();

    }).unsubscribeOn(this.unsubscribeSubject);
  }

  public initUIModels(): void {

    this.models = [];

    // In grouped case Id would be null and children are only used but in ungrouped case Id is not null and children are null.
    let ids: Array<number> = this.confUIItem.id ? [this.confUIItem.id] : this.confUIItem.items.map(x => x.id).toArray();

    ids.forEach(decorationId => {

      let uiModel: UIBomModel = new UIBomModel();
      uiModel.decoration = this.productStore.getEntity<BomDecoration>(decorationId);
      this.models.push(uiModel);

    });

  }

  public onDetailsClick(decoration: Decoration, stored = false): void {
    this.compositeVisible = true;
    sleep(Delay.MS_100).then(x => { this.popupService.open<BomActionArgs>(PopupIdentifiers.BomComposite, <BomActionArgs>{ configurationId: this.configurationId, decorationId: decoration.longId, stored: stored }); });
  }

  onDecorationHeaderIconClicked(): void {

    // If the click event is triggered outside the bomDecorations loop's scope then we don't know which decoration is clicked.
    // But It would only happen if there is only one decoration in the list.
    if (this.models.length == 0)
      return;

    this.onDetailsClick(this.models[0].decoration, !this.models[0].confBomValue);
  }

  public trackModel(index: number, model: UIBomModel): any {
    return model.decoration.longId;
  }

}