import * as Immutable from "immutable";
import { StoredPriceValue, StoredCodeValue, StoredDataSelectorValue, StoredAttributeValue, StoredBomValue } from "..";
import { BaseEntity } from "../../baseEntity";

export class StoredConf extends BaseEntity {

  protected readonly ATTRIBUTE_VALUES = "attributeValues";
  protected readonly PRICE_VALUE = "priceValue";
  protected readonly CODE_VALUES = "codeValues";
  protected readonly DATA_SELECTOR_VALUES = "dataSelectorValues";
  protected readonly BOM_VALUES = "bomValues";  

  get attributeValues(): Immutable.List<StoredAttributeValue> { return this.getInternalValue<Immutable.List<StoredAttributeValue>>(this.ATTRIBUTE_VALUES); }
  setattributeValues(attributeValues: Immutable.List<StoredAttributeValue>): this { return this.setInternalValue(this.ATTRIBUTE_VALUES, attributeValues); }

  get priceValue(): StoredPriceValue { return this.getInternalValue<StoredPriceValue>(this.PRICE_VALUE); }
  setPriceValue(priceValue: StoredPriceValue): this { return this.setInternalValue(this.PRICE_VALUE, priceValue); }  

  get codeValues(): Immutable.List<StoredCodeValue> { return this.getInternalValue<Immutable.List<StoredCodeValue>>(this.CODE_VALUES); }
  setCodeValues(codeValues: Immutable.List<StoredCodeValue>): this { return this.setInternalValue(this.CODE_VALUES, codeValues); }

  get dataSelectorValues(): Immutable.List<StoredDataSelectorValue> { return this.getInternalValue<Immutable.List<StoredDataSelectorValue>>(this.DATA_SELECTOR_VALUES); }
  setDataSelectorValues(dataSelectorValues: Immutable.List<StoredDataSelectorValue>): this { return this.setInternalValue(this.DATA_SELECTOR_VALUES, dataSelectorValues); }

  get bomValues(): Immutable.List<StoredBomValue> { return this.getInternalValue<Immutable.List<StoredBomValue>>(this.BOM_VALUES); }
  setBomValues(bomValues: Immutable.List<StoredBomValue>): this { return this.setInternalValue(this.BOM_VALUES, bomValues); }

}