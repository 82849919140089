import { Inject, Injectable } from '@angular/core';
import * as Immutable from "immutable";
import { ManagedSubscription, SubscriptionOptions } from "../../../../shared/managedSubscription";
import { BomAutoCompleteMessage, BomCommand, BomDetailMessage, BomItem, BomItemMessage, BomOperationMessage, ConfDataRequest, ConfResponseFormat, GridArgument, PaginationArgument, RequestViews } from "../../../shared/models";
import { AppStoreSubscriptionManager, ModelFactory, RouteRedirector } from "../../../shared/providers";
import { GlobalDataStore } from "../../../shared/providers/globalData";
import { AppStore, BaseStore } from "../../../shared/state";
import { ConfMessageProvider, ConfiguratorStore, ConfDataMemorizer, ConfiguratorUIStore } from "../../providers";
import { BomActionCreator } from "./bomActionCreator";
import { ConfDataActionCreator } from '../../../shared/providers/configurationData';

@Injectable()
export class BomStore extends ConfiguratorStore {

  constructor(
    @Inject(AppStore) public appStore: AppStore,
    @Inject(BomActionCreator) public actionCreator: BomActionCreator,
    @Inject(AppStoreSubscriptionManager) public appStoreSubscriptionManager: AppStoreSubscriptionManager,
    @Inject(ConfMessageProvider) public messagesProvider: ConfMessageProvider,    
    @Inject(ConfDataActionCreator) public confActionCreator: ConfDataActionCreator,    
    @Inject(ConfDataMemorizer) public memorizer: ConfDataMemorizer,
    @Inject(ConfMessageProvider) public confMessageProvider: ConfMessageProvider,
    @Inject(ModelFactory) public modelFactory: ModelFactory,
    @Inject(GlobalDataStore) public globalDataStore: GlobalDataStore,
    @Inject(RouteRedirector) public routeRredirector: RouteRedirector,
    @Inject(ConfiguratorUIStore) public confUIStore: ConfiguratorUIStore
  ) {
    super(appStore, confActionCreator, appStoreSubscriptionManager, memorizer, confMessageProvider, modelFactory, globalDataStore, routeRredirector, confUIStore);
  }

  public onRowMessageRequest(confSessionId: number, callback: (messages: Immutable.List<BomOperationMessage>) => void): ManagedSubscription {
    return this.messagesProvider.onMessagesRequest<BomOperationMessage>(confSessionId, BomOperationMessage.name, callback);
  }

  public onGetBomDetailGridData(confSessionId: number, subscriptionOptions: SubscriptionOptions<Immutable.List<BomDetailMessage>>): ManagedSubscription {
    return this.messagesProvider.onMessagesRequest<BomDetailMessage>(confSessionId, BomDetailMessage.name, subscriptionOptions);
  }

  public onSearchBom(confSessionId: number, subscriptionOptions: SubscriptionOptions<Immutable.List<BomAutoCompleteMessage>>): ManagedSubscription {
    return this.messagesProvider.onMessagesRequest<BomAutoCompleteMessage>(confSessionId, BomAutoCompleteMessage.name, subscriptionOptions);
  }

  public onGetItemRequest(confSessionId: number, callback: (messages: Immutable.List<BomItemMessage>) => void): ManagedSubscription {
    return this.messagesProvider.onMessagesRequest<BomItemMessage>(confSessionId, BomItemMessage.name, callback);
  }

  public dispatchGetBomGridData(requestClientType: RequestViews, configurationId: number, confSessionId: number, bomDecorationId: number, pageSize: number, pageIndex: number, stored = false) {
    // Page index defaults 1
    pageIndex = pageIndex || 1;

    let model = super.createRequest();
    model.client = requestClientType;
    model.confSessionId = confSessionId;
    model.bom = this.modelFactory.createRequestOrCommand<BomCommand>(BomCommand.name, { configurationId: configurationId, bomDecorationId: bomDecorationId });
    
    model.bom.responseFormat = this.globalDataStore.getConfResponseFormat(requestClientType);
    model.bom.gridData = this.modelFactory.createAny<GridArgument>(GridArgument.name);
    model.bom.gridData.pagination = this.modelFactory.createAny<PaginationArgument>(PaginationArgument.name, { pageSize: pageSize, pageIndex: pageIndex });

    model.bom.stored = stored;

    this.appStore.dispatch(this.actionCreator.getBomGridData(model));
  }

  public dispatchGetBomDetailGridData(requestClientType: RequestViews, configurationId: number, confSessionId: number, bomDecorationId: number, pageSize?: number, pageIndex?: number, stored?: boolean) {
    let model = super.createRequest();
    model.client = requestClientType;
    model.confSessionId = confSessionId;

    model.bom = this.modelFactory.createRequestOrCommand<BomCommand>(BomCommand.name, { configurationId: configurationId, bomDecorationId: bomDecorationId });
    model.bom.responseFormat = this.modelFactory.createAny<ConfResponseFormat>(ConfResponseFormat.name, { bom: true });
    model.bom.detailGridData = this.modelFactory.createAny<GridArgument>(GridArgument.name);    
    model.bom.detailGridData.pagination = this.modelFactory.createAny<PaginationArgument>(PaginationArgument.name, { pageSize: pageSize, pageIndex: pageIndex });
    model.bom.stored = stored;

    this.appStore.dispatch(this.actionCreator.getBomDetailGridData(model));
  }

  public dispatchSearchBom(configurationId: number, confSessionId: number, bomDecorationId: number, query: string) {
    let model = super.createRequest();
    model.confSessionId = confSessionId;
    model.client = RequestViews.Editor;

    model.bom = this.modelFactory.createRequestOrCommand<BomCommand>(BomCommand.name, { configurationId: configurationId, bomDecorationId: bomDecorationId, search: query });

    this.appStore.dispatch(this.actionCreator.searchBom(model));
  }

  public dispatchCreateBom(configurationId: number, confSessionId: number, bomDecorationId: number, itemNo: string, revision: string, quantity: number, quantityUnitId: number, operationSequenceId: number) {
    let model = super.createRequest();
    model.client = RequestViews.Editor;
    model.confSessionId = confSessionId;

    model.bom = this.modelFactory.createRequestOrCommand<BomCommand>(BomCommand.name, { configurationId: configurationId, bomDecorationId: bomDecorationId });
    model.bom.create = this.modelFactory.createRequestOrCommand<BomItem>(BomItem.name, { itemNo: itemNo, revision: revision });
    model.bom.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Editor);

    if (quantity)
      model.bom.create.quantity = quantity;
    if (quantityUnitId)
      model.bom.create.quantityUnitId = quantityUnitId;
    if (operationSequenceId)
      model.bom.create.operationSequenceId = operationSequenceId;

    this.appStore.dispatch(this.actionCreator.createBom(model));
  }

  public dispatchEditBom(configurationId: number, confSessionId: number, bomDecorationId: number, id: number, itemNo: string, revision: string, quantity: number, quantityUnitId: number, operationSequenceId: number) {
    let model = super.createRequest();
    model.client = RequestViews.Editor;
    model.confSessionId = confSessionId;

    model.bom = this.modelFactory.createRequestOrCommand<BomCommand>(BomCommand.name, { configurationId: configurationId, bomDecorationId: bomDecorationId });
    model.bom.edit = this.modelFactory.createAny<BomItem>(BomItem.name, { id: id, itemNo: itemNo, revision: revision });
    model.bom.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Editor);

    if (quantity)
      model.bom.edit.quantity = quantity;
    if (quantityUnitId)
      model.bom.edit.quantityUnitId = quantityUnitId;
    if (operationSequenceId)
      model.bom.edit.operationSequenceId = operationSequenceId;
    
    this.appStore.dispatch(this.actionCreator.editBom(model));
  }

  public dispatchRemoveBom(configurationId: number, confSessionId: number, bomDecorationId: number, id: number) {
    let model = super.createRequest();
    model.client = RequestViews.Editor;
    model.confSessionId = confSessionId;
    
    model.bom = this.modelFactory.createRequestOrCommand<BomCommand>(BomCommand.name, { configurationId: configurationId, bomDecorationId: bomDecorationId });
    model.bom.responseFormat = this.globalDataStore.getConfResponseFormat(RequestViews.Editor);
    model.bom.remove = this.modelFactory.createAny<BomItem>(BomItem.name, { id: id });
    
    this.appStore.dispatch(this.actionCreator.removeBom(model));
  }

  public dispatchGetItem(configurationId: number, confSessionId: number, bomDecorationId: number, id: number) {
    let model = super.createRequest();
    model.client = RequestViews.Editor;
    model.confSessionId = confSessionId;

    model.bom = this.modelFactory.createRequestOrCommand<BomCommand>(BomCommand.name, { configurationId: configurationId, bomDecorationId: bomDecorationId });
    model.bom.getItem = this.modelFactory.createAny<BomItem>(BomItem.name, { id: id });

    this.appStore.dispatch(this.actionCreator.getItem(model));
  }

}