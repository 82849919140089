<backdrop-container>
  <regular-popup *ngIf="visible"
                 [title]="mainTitle"
                 width="90%"
                 fullHeight="true"
                 [showHeader]="true"
                 position="right"
                 [okButtonText]="strings.Ok"
                 [cancelButtonText]="strings.Cancel"
                 (onClosed)="onPopupClosed()">

    <div card-type="breadcrumb" *ngIf="stack.size > 0">
      <nav role="navigation">
        <ol class="breadcrumb mb-0 pt-0 pb-2 ml-3">
          <li *ngFor="let conf of stack; let last = last" [class.active]="last" class="breadcrumb-item">
            <a (click)="update(conf.longId)" role="button">{{conf.text}} <span class="sub-text">({{conf.longId}})</span></a>
          </li>
        </ol>
      </nav>
    </div>

    <div card-type="header" class="bom-composite pt-2">
      <div class="row px-4 mt-0">
        <co-image key="product" width="32px" imageSet="{{imageSet}}" height="32px" class="col-auto px-0"> </co-image>
        <div class="col composite-title align-middle">
          {{compositeTitle}}
        </div>
      </div>
    </div>

    <div card-type="body" class="bom-composite bom-table px-4 pt-4 pb-3" *ngIf="currentBomMessage != null">
      <bom-table [width]="width"
                 [grid]="currentBomMessage.gridData"
                 [columnSettings]="columnSettings"
                 [detailsMode]="true"
                 [decorationId]="bomDecoration.hasFlowTrigger ? bomDecoration.longId : ''">
      </bom-table>
      <div *ngIf="displayChildConfigurations">
        <br />
        <gridview>
          <gridview-header>
            <header-columntemplate classes="header-image" size="auto">
              <co-image width="24" height="24" type="svg" key="configuration" imageSet="{{imageSet}}"></co-image>
            </header-columntemplate>
            <header-columntemplate classes="header-title">
              {{strings.ChildConfigurations}}
            </header-columntemplate>
          </gridview-header>
        </gridview>
        <bom-table [width]="width"
                   [grid]="currentBomMessage.childrenGridData"
                   [columnSettings]="childColumnSettings"
                   [detailsMode]="true"
                   (selectionChanged)="update($event.id)"></bom-table>
      </div>
      <bom-grand-total [grid]="currentBomMessage.grandTotal"></bom-grand-total>
    </div>
  </regular-popup>
</backdrop-container>