import { SimpleChanges, Output, Component, ViewEncapsulation, Injectable, ElementRef, Input, Inject, OnChanges, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from "rxjs";

import { NodeEvent, TreeDataProvider, NodeData } from '../index';
import { isLeftButtonClicked } from "../../utils/eventUtils";
import { TreeViewEventService } from './treeViewEventService';
import { NodeActions, EventSource } from "./treeEvents";
import { TreeExpandBehaviour } from './treeExpandBehaviour';
import { SelectorTreeDataProvider } from '../../../pages/selector/providers/selectorTreeDataProvider';

@Component({
  selector: 'tree-node',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './treeNodeComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeNodeComponent {

  @Input() public node: NodeData;
  @Input() public selected: boolean = false;
  @Input() public expanded: boolean = false;
  @Input() public dataProvider: TreeDataProvider;

  @Input() public imageSet = "primary";

  public enableExpandCollapseOnTitle: boolean = false;
  public expandOnLoad: boolean = false;
  public expandable: boolean = false;
  public depth: number = 1;
  public children: NodeData[] = [];
  public iconMoreStyles: string = "";
  public extraStyles: string = "";

  // Should support menu ?
  public menuSupport: boolean = false;

  // Is menu activated.  
  public menuActive: boolean = false;

  // Is menu activated.  
  public addActive: boolean = false;

  public messageSubscription: Subscription;

  public indentationSpanCount: number;

  public showExpandCollapseIcon: boolean = true;

  public highlightSelected: boolean = false;

  // Display the product family information on second click in small screens
  public openNext = false;

  public constructor(
    @Inject(TreeViewEventService) public treeViewEventService: TreeViewEventService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    // Set the settings.
    this.updateSettings();
    this.setIndentation();
    this.extraStyles = this.node.styles;
    this.applyDefaultExpansion();

    if (this.dataProvider.defaultSelectedId == this.node.id) {

      this.dataProvider.sendMessage(<NodeEvent>{ action: NodeActions.DeactivateAny });
      this.selected = true;
      
    }

    // Subscribe the ui changes.
    this.messageSubscription = this.dataProvider.getMessage().subscribe((event: NodeEvent) => {

      let localNodeId = this.node.id;
      switch (event.action) {
        // Tree refresh message
        case NodeActions.TreeRefresh:
          {
            this.showChildren();
            this.setExpandableStatus();
            this.menuSupport = this.dataProvider.menuSupport(event.node);
            this.cd.markForCheck(); // Mark the path to refresh it.
            return;
          }

        // If the selection is made forcefully through <TreeDataProvider> service.
        case NodeActions.BeforeSelect:
          {
            // Turn the previous selected state 'false' except the current active node.
            if (event.node.id == this.node.id && !this.selected) {
              // Make the tree node selected.
              this.selectNode(event);
              this.cd.markForCheck();
            }            

            break;
          }

        // Triggered internally to remove the previous selected state.
        case NodeActions.AfterSelect:
          {
            // Turn the previous selected state 'false' except the current active node.
            if (event.node.id != this.node.id ) {
              this.selected = false;
              this.cd.markForCheck();
            }

            break;
          }

        case NodeActions.DataChanged:
          {
            if (event.node.id == this.node.id) {
              this.node = event.node;
              this.showChildren();
              this.cd.markForCheck();
            }

            break;
          }

        // Collapse all siblings except current one.
        case NodeActions.CollapseSibling:
          {
            if (this.node.id != event.node.id && this.belongsToSameParent(event.node.id, this.node.id)) {
              this.expanded = false;
              this.cd.markForCheck();
            }

            break;
          }

        case NodeActions.MenuClicked:
          {
            // De-activate the previous selection.
            if (this.node.id != event.node.id) {

              this.menuActive = false;
              this.cd.markForCheck();
            }

            break;
          }

        case NodeActions.DeactivateMenu:        
          {
            // Resets the menu to its default state
            if (this.menuActive) {

              this.menuActive = false;
              this.cd.markForCheck();
            }

            break;
          }

        case NodeActions.ActivateMenu:
          {
            // Activate the menu                        
            this.menuActive = this.node.id == event.node.id;
            this.addActive = false;            
            this.cd.markForCheck();
            break;
          }

        case NodeActions.ActivateAddMenu:
          {
            // Activate the menu                                 
            this.menuActive = false;
            this.addActive = this.node.id == event.node.id;
            this.cd.markForCheck();

            break;
          }

        case NodeActions.DeactivateAddMenu:
          {
            // Activate the menu
            if (this.node.id == event.node.id) {
              this.addActive = false;
              this.cd.markForCheck();
            }
            
            break;
          }

        case NodeActions.DeactivateAny:
          {
            // Deactivate the menu            
            this.addActive = false;
            this.menuActive = false;
            this.selected = false;
            this.cd.markForCheck();

            break;
          }

      }
    });

    // Expand the node on load.
    if (this.expandOnLoad)
      this.expanded = true;

    this.showChildren();
    this.setExpandableStatus();
  }

  belongsToSameParent(targetParentId: number, currentNodeId: number): boolean {

    if (targetParentId == currentNodeId)
      return true;

    let parentNode: NodeData = this.dataProvider.get(currentNodeId);

    if (parentNode) 
      return this.belongsToSameParent(targetParentId, parentNode.parentId);
    

    return false;
  }

  setIndentation(): void {

    // Root indentation
    if (!this.node.parentId) {
      this.indentationSpanCount = null;
      return;
    }

    // Child node indentation is multiplied with the node depth level.
    this.indentationSpanCount = this.depth - 1;

  }

  onNodeClick(e: MouseEvent): void {
    if (isLeftButtonClicked(e)) {
      // Dont select node if in small screen and expanding the product family for the first time
      let shouldOpen = !this.expandable || window.innerWidth > 767 || this.openNext || !(this.dataProvider instanceof SelectorTreeDataProvider);

      if (this.enableExpandCollapseOnTitle && !this.openNext)
        this.onExpandCollapseClick(e);

      if (shouldOpen) {
        let event = <NodeEvent>{ action: NodeActions.AfterSelect, eventSource: EventSource.NodeSelected, node: this.node };
        this.selectNode(event);
        this.openNext = false;
      }
      else
        this.openNext = this.expanded;
    }
  }

  /**
   * Execute the select action.
   */
  public selectNode(event: NodeEvent): void {
    this.selected = true;

    event.action = NodeActions.AfterSelect;
    this.dataProvider.sendMessage(event);

    // Notify to component.
    this.treeViewEventService.nodeSelect$.next(event);
  }

  /**
   * Fires when making expand/collapse click.
   * @param e
   */
  public onExpandCollapseClick(e: MouseEvent): void {

    this.expanded = !this.expanded;

    this.showChildren();

    if (!this.expanded)
      this.treeViewEventService.nodeCollapse$.next(<NodeEvent>{ node: this.node });

    else this.treeViewEventService.nodeExpand$.next(<NodeEvent>{ node: this.node });

    if (this.dataProvider.singleExpand()) {
      let event = <NodeEvent>{ action: NodeActions.CollapseSibling, node: this.node };
      this.dataProvider.sendMessage(event);
    }

  }

  public onMenuClick(): void {
    
    let event = <NodeEvent>{ action: NodeActions.MenuClicked, eventSource: EventSource.MenuClick, node: this.node };
    this.treeViewEventService.nodeMenuClick$.next(event);    
    this.dataProvider.sendMessage(event); 
  }

  public onAddClick(): void {

    let event = <NodeEvent>{ action: NodeActions.AddClicked, eventSource: EventSource.AddClick, node: this.node };
    this.treeViewEventService.nodeAddClick$.next(event);
    this.dataProvider.sendMessage(event); 

  }

  /**
   * Shows the children
   */
  public showChildren(): void {

    if (!this.expanded) {
      return;
    }
    
    this.dataProvider.loadChildren(this.node, (children: NodeData[]) => {
      this.children = children;
    });
  }

  /**
   * Sets the expandable status.
   */
  setExpandableStatus(): void {
    this.expandable = this.dataProvider.isNodeExpandable(this.node);
  }

  /**
   * Updates the settings.
   */
  updateSettings(): void {

    this.depth = this.dataProvider.distanceFromRoot(this.node.id);
    this.enableExpandCollapseOnTitle = this.dataProvider.enableExpandCollapseOnTitleClick(this.node);
    this.expandOnLoad = this.dataProvider.expandOnLoad();
    this.menuSupport = this.dataProvider.menuSupport(this.node);
    this.showExpandCollapseIcon = this.dataProvider.showExpandCollapseIcon;
    this.highlightSelected = this.dataProvider.highlightSelectedNode;
  }

  applyDefaultExpansion(): void {

    switch (this.dataProvider.expandBehaviour) {

      case TreeExpandBehaviour.ExpandNoInitial:
        if (this.dataProvider.belongsToSelectedPath(this.node.id))
          this.onExpandCollapseClick(null);
        break;

      case TreeExpandBehaviour.ExpandAll:        
        this.expanded = true;
        break;

      case TreeExpandBehaviour.ExpandFirstLevel:
        if (this.dataProvider.belongsToSelectedPath(this.node.id) || this.depth <= 1 && !this.expanded)
          this.onExpandCollapseClick(null);

        break;

      case TreeExpandBehaviour.ExpandSecondLevel:
        if (this.dataProvider.belongsToSelectedPath(this.node.id) || this.depth <= 2 && !this.expanded)
          this.onExpandCollapseClick(null);

        break;
    }    
  }

  ngOnDestroy() {

    if (this.messageSubscription)
      this.messageSubscription.unsubscribe();
  }
}